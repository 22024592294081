:root {
    --accent-color: rgb(234, 242, 255);
}

* {
    padding: 0;
    margin: 0;
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-size: 14px;
}

body {
    background-color: rgb(255, 255, 255);
}

#title {
    font-size: 28px;
    text-align: center;
    margin: 20px;
}

#selections {
    margin: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

select {
    background-color: var(--accent-color);
}

#speak-label {
    font-size: 20px;
    font-weight: bold;
}

#speak-button {
    border-radius: 50%;
    padding: 10px 12px;
    margin-top: 20px;
    background-color: var(--accent-color);
}

@keyframes fade {
    0% {
        background-color: red;
    }
    50% {
        background-color: rgb(255, 255, 255);
    }
    100% {
        background-color: red;
    }
}

#speak-button.active {
    animation-name: fade;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

#speak-button-container {
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* border: 1px solid black; */
    /* background-color: var(--accent-color); */
}

#main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#asr-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 900px;
}

.asr-label {
    margin-top: 20px;
}

.asr-text {
    border: 1px solid black;
    min-height: 30px;
}

#replay-button {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid black;
    background-color: var(--accent-color);
}
